/**======================================================================
=========================================================================
Template Name: Able Pro - Bootstrap Admin Template
Author: codedthemes
Support: https://codedthemes.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
h1,
h2 {
  font-weight: 700;
}

:root {
  --bs-body-bg: #fafafb;
  --bs-body-bg-rgb: 250, 250, 251;
  --pc-heading-color: #343a40;
  --pc-active-background: #e9ecef;
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #141414;
  --pc-sidebar-color-rgb: 20, 20, 20;
  --pc-sidebar-active-color: var(--bs-primary);
  --pc-sidebar-shadow: 1px 0 0 0px rgb(240 240 240);
  --pc-sidebar-caption-color: #495057;
  --pc-header-background: #fff;
  --pc-header-color: #141414;
  --pc-header-shadow: 0 1px 0 0px rgb(240 240 240);
  --pc-card-box-shadow: none;
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #6c757d;
}

[data-pc-theme_contrast=true] {
  --bs-body-bg: #ffffff;
  --pc-sidebar-background: transparent;
  --pc-sidebar-active-color: #1890ff;
  --pc-sidebar-shadow: 1px 0 3px 0px #dee2e6;
  --pc-sidebar-border: none;
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

[data-pc-preset=preset-1] {
  --pc-sidebar-active-color: #1890ff;
  --bs-blue: #1890ff;
  --bs-primary: #1890ff;
  --bs-primary-rgb: 24, 144, 255;
  --bs-primary-light: #e8f4ff;
  --bs-link-color: #1890ff;
  --bs-link-color-rgb: 24, 144, 255;
  --bs-link-hover-color: #1373cc;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 24, 144, 255;
}
[data-pc-preset=preset-1] .bg-light-primary {
  background: #e8f4ff;
  color: #1890ff;
}
[data-pc-preset=preset-1] .link-primary {
  color: #1890ff !important;
}
[data-pc-preset=preset-1] .link-primary:hover, [data-pc-preset=preset-1] .link-primary:focus {
  color: #1373cc !important;
}
[data-pc-preset=preset-1] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1890ff;
  --bs-btn-border-color: #1890ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #147ad9;
  --bs-btn-hover-border-color: #1373cc;
  --bs-btn-focus-shadow-rgb: 59, 161, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1373cc;
  --bs-btn-active-border-color: #126cbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1890ff;
  --bs-btn-disabled-border-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-link {
  --bs-btn-color: #1890ff;
  --bs-btn-hover-color: #1373cc;
  --bs-btn-active-color: #1373cc;
}
[data-pc-preset=preset-1] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(24, 144, 255, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-1] .accordion {
  --bs-accordion-btn-focus-border-color: #1890ff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.25);
  --bs-accordion-active-color: #1890ff;
  --bs-accordion-active-bg: #e8f4ff;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231890ff'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .alert-primary {
  --bs-alert-color: #0e5699;
  --bs-alert-bg: #d1e9ff;
  --bs-alert-border-color: #badeff;
  --bs-alert-link-color: #0b457a;
}
[data-pc-preset=preset-1] .list-group {
  --bs-list-group-active-bg: #1890ff;
  --bs-list-group-active-border-color: #1890ff;
}
[data-pc-preset=preset-1] .list-group-item-primary {
  color: #0e5699;
  background-color: #d1e9ff;
}
[data-pc-preset=preset-1] .nav {
  --bs-nav-link-hover-color: #1373cc;
}
[data-pc-preset=preset-1] .nav-pills {
  --bs-nav-pills-link-active-bg: #1890ff;
}
[data-pc-preset=preset-1] .pagination {
  --bs-pagination-hover-color: #1373cc;
  --bs-pagination-focus-color: #1373cc;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.25);
  --bs-pagination-active-bg: #1890ff;
  --bs-pagination-active-border-color: #1890ff;
}
[data-pc-preset=preset-1] .progress {
  --bs-progress-bar-bg: #1890ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:checked {
  border-color: #1890ff;
  background-color: #1890ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e8f4ff;
  background-color: #e8f4ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231890ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231890ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.25);
  border-color: #1890ff;
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231890ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .btn-light-primary {
  background: #e8f4ff;
  color: #1890ff;
  border-color: #e8f4ff;
}
[data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #1890ff;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #1890ff;
}
[data-pc-preset=preset-1] .btn-link-primary:hover {
  background: #e8f4ff;
  color: #1890ff;
  border-color: #e8f4ff;
}
[data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-preset=preset-1] .btn-link-primary:focus {
  background: #e8f4ff;
  color: #1890ff;
  border-color: #e8f4ff;
}
[data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: #e8f4ff;
  color: #1890ff;
  border-color: #e8f4ff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: #e8f4ff;
  color: #1890ff;
  border-color: #e8f4ff;
}
[data-pc-preset=preset-1] .slider-selection {
  background-image: linear-gradient(to bottom, #98cdff 0, #98cdff 100%);
}
[data-pc-preset=preset-1] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #7ec1ff 0, #7ec1ff 100%);
}
[data-pc-preset=preset-1] .swal-button:not([disabled]):hover {
  background-color: #0084fe;
}
[data-pc-preset=preset-1] .swal-button:active {
  background-color: #0084fe;
}
[data-pc-preset=preset-1] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(24, 144, 255, 0.29);
}
[data-pc-preset=preset-1] .swal-content__input:focus {
  border-color: rgba(24, 144, 255, 0.29);
}
[data-pc-preset=preset-1] .swal-content__textarea:focus {
  border-color: rgba(24, 144, 255, 0.29);
}
[data-pc-preset=preset-1] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(24, 144, 255, 0.4) !important;
}
[data-pc-preset=preset-1] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #7ec1ff 0, #7ec1ff 100%);
}
[data-pc-preset=preset-1] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #1890ff;
  --bs-table-border-color: #2f9bff;
  --bs-table-striped-bg: #2496ff;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2f9bff;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #1d92ff;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-2] {
  --pc-sidebar-active-color: #3366ff;
  --bs-blue: #3366ff;
  --bs-primary: #3366ff;
  --bs-primary-rgb: 51, 102, 255;
  --bs-primary-light: #ebf0ff;
  --bs-link-color: #3366ff;
  --bs-link-color-rgb: 51, 102, 255;
  --bs-link-hover-color: #2952cc;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 51, 102, 255;
}
[data-pc-preset=preset-2] .bg-light-primary {
  background: #ebf0ff;
  color: #3366ff;
}
[data-pc-preset=preset-2] .link-primary {
  color: #3366ff !important;
}
[data-pc-preset=preset-2] .link-primary:hover, [data-pc-preset=preset-2] .link-primary:focus {
  color: #2952cc !important;
}
[data-pc-preset=preset-2] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3366ff;
  --bs-btn-border-color: #3366ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2b57d9;
  --bs-btn-hover-border-color: #2952cc;
  --bs-btn-focus-shadow-rgb: 82, 125, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2952cc;
  --bs-btn-active-border-color: #264dbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3366ff;
  --bs-btn-disabled-border-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-link {
  --bs-btn-color: #3366ff;
  --bs-btn-hover-color: #2952cc;
  --bs-btn-active-color: #2952cc;
}
[data-pc-preset=preset-2] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(51, 102, 255, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-2] .accordion {
  --bs-accordion-btn-focus-border-color: #3366ff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
  --bs-accordion-active-color: #3366ff;
  --bs-accordion-active-bg: #ebf0ff;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233366ff'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .alert-primary {
  --bs-alert-color: #1f3d99;
  --bs-alert-bg: #d6e0ff;
  --bs-alert-border-color: #c2d1ff;
  --bs-alert-link-color: #19317a;
}
[data-pc-preset=preset-2] .list-group {
  --bs-list-group-active-bg: #3366ff;
  --bs-list-group-active-border-color: #3366ff;
}
[data-pc-preset=preset-2] .list-group-item-primary {
  color: #1f3d99;
  background-color: #d6e0ff;
}
[data-pc-preset=preset-2] .nav {
  --bs-nav-link-hover-color: #2952cc;
}
[data-pc-preset=preset-2] .nav-pills {
  --bs-nav-pills-link-active-bg: #3366ff;
}
[data-pc-preset=preset-2] .pagination {
  --bs-pagination-hover-color: #2952cc;
  --bs-pagination-focus-color: #2952cc;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
  --bs-pagination-active-bg: #3366ff;
  --bs-pagination-active-border-color: #3366ff;
}
[data-pc-preset=preset-2] .progress {
  --bs-progress-bar-bg: #3366ff;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:checked {
  border-color: #3366ff;
  background-color: #3366ff;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ebf0ff;
  background-color: #ebf0ff;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233366ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233366ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
  border-color: #3366ff;
}
[data-pc-preset=preset-2] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233366ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .btn-light-primary {
  background: #ebf0ff;
  color: #3366ff;
  border-color: #ebf0ff;
}
[data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #3366ff;
  color: #fff;
  border-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #3366ff;
  color: #fff;
  border-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #3366ff;
  color: #fff;
  border-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #3366ff;
  color: #fff;
  border-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #3366ff;
  border-color: transparent;
}
[data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #3366ff;
}
[data-pc-preset=preset-2] .btn-link-primary:hover {
  background: #ebf0ff;
  color: #3366ff;
  border-color: #ebf0ff;
}
[data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-preset=preset-2] .btn-link-primary:focus {
  background: #ebf0ff;
  color: #3366ff;
  border-color: #ebf0ff;
}
[data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: #ebf0ff;
  color: #3366ff;
  border-color: #ebf0ff;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: #ebf0ff;
  color: #3366ff;
  border-color: #ebf0ff;
}
[data-pc-preset=preset-2] .slider-selection {
  background-image: linear-gradient(to bottom, #b3c6ff 0, #b3c6ff 100%);
}
[data-pc-preset=preset-2] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #99b3ff 0, #99b3ff 100%);
}
[data-pc-preset=preset-2] .swal-button:not([disabled]):hover {
  background-color: #1a53ff;
}
[data-pc-preset=preset-2] .swal-button:active {
  background-color: #1a53ff;
}
[data-pc-preset=preset-2] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(51, 102, 255, 0.29);
}
[data-pc-preset=preset-2] .swal-content__input:focus {
  border-color: rgba(51, 102, 255, 0.29);
}
[data-pc-preset=preset-2] .swal-content__textarea:focus {
  border-color: rgba(51, 102, 255, 0.29);
}
[data-pc-preset=preset-2] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(51, 102, 255, 0.4) !important;
}
[data-pc-preset=preset-2] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #99b3ff 0, #99b3ff 100%);
}
[data-pc-preset=preset-2] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3366ff;
  --bs-table-border-color: #4775ff;
  --bs-table-striped-bg: #3d6eff;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #4775ff;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #3769ff;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-3] {
  --pc-sidebar-active-color: #7265e6;
  --bs-blue: #7265e6;
  --bs-primary: #7265e6;
  --bs-primary-rgb: 114, 101, 230;
  --bs-primary-light: #f1f0fd;
  --bs-link-color: #7265e6;
  --bs-link-color-rgb: 114, 101, 230;
  --bs-link-hover-color: #5b51b8;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 114, 101, 230;
}
[data-pc-preset=preset-3] .bg-light-primary {
  background: #f1f0fd;
  color: #7265e6;
}
[data-pc-preset=preset-3] .link-primary {
  color: #7265e6 !important;
}
[data-pc-preset=preset-3] .link-primary:hover, [data-pc-preset=preset-3] .link-primary:focus {
  color: #5b51b8 !important;
}
[data-pc-preset=preset-3] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #7265e6;
  --bs-btn-border-color: #7265e6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6156c4;
  --bs-btn-hover-border-color: #5b51b8;
  --bs-btn-focus-shadow-rgb: 135, 124, 234;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5b51b8;
  --bs-btn-active-border-color: #564cad;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #7265e6;
  --bs-btn-disabled-border-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-link {
  --bs-btn-color: #7265e6;
  --bs-btn-hover-color: #5b51b8;
  --bs-btn-active-color: #5b51b8;
}
[data-pc-preset=preset-3] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(114, 101, 230, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-3] .accordion {
  --bs-accordion-btn-focus-border-color: #7265e6;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(114, 101, 230, 0.25);
  --bs-accordion-active-color: #7265e6;
  --bs-accordion-active-bg: #f1f0fd;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237265e6'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .alert-primary {
  --bs-alert-color: #443d8a;
  --bs-alert-bg: #e3e0fa;
  --bs-alert-border-color: #d5d1f8;
  --bs-alert-link-color: #36316e;
}
[data-pc-preset=preset-3] .list-group {
  --bs-list-group-active-bg: #7265e6;
  --bs-list-group-active-border-color: #7265e6;
}
[data-pc-preset=preset-3] .list-group-item-primary {
  color: #443d8a;
  background-color: #e3e0fa;
}
[data-pc-preset=preset-3] .nav {
  --bs-nav-link-hover-color: #5b51b8;
}
[data-pc-preset=preset-3] .nav-pills {
  --bs-nav-pills-link-active-bg: #7265e6;
}
[data-pc-preset=preset-3] .pagination {
  --bs-pagination-hover-color: #5b51b8;
  --bs-pagination-focus-color: #5b51b8;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(114, 101, 230, 0.25);
  --bs-pagination-active-bg: #7265e6;
  --bs-pagination-active-border-color: #7265e6;
}
[data-pc-preset=preset-3] .progress {
  --bs-progress-bar-bg: #7265e6;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:checked {
  border-color: #7265e6;
  background-color: #7265e6;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f1f0fd;
  background-color: #f1f0fd;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%237265e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%237265e6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(114, 101, 230, 0.25);
  border-color: #7265e6;
}
[data-pc-preset=preset-3] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%237265e6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .btn-light-primary {
  background: #f1f0fd;
  color: #7265e6;
  border-color: #f1f0fd;
}
[data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #7265e6;
  color: #fff;
  border-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #7265e6;
  color: #fff;
  border-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #7265e6;
  color: #fff;
  border-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #7265e6;
  color: #fff;
  border-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #7265e6;
  border-color: transparent;
}
[data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #7265e6;
}
[data-pc-preset=preset-3] .btn-link-primary:hover {
  background: #f1f0fd;
  color: #7265e6;
  border-color: #f1f0fd;
}
[data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-preset=preset-3] .btn-link-primary:focus {
  background: #f1f0fd;
  color: #7265e6;
  border-color: #f1f0fd;
}
[data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: #f1f0fd;
  color: #7265e6;
  border-color: #f1f0fd;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: #f1f0fd;
  color: #7265e6;
  border-color: #f1f0fd;
}
[data-pc-preset=preset-3] .slider-selection {
  background-image: linear-gradient(to bottom, #d6d3f8 0, #d6d3f8 100%);
}
[data-pc-preset=preset-3] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #c2bdf4 0, #c2bdf4 100%);
}
[data-pc-preset=preset-3] .swal-button:not([disabled]):hover {
  background-color: #5e4fe2;
}
[data-pc-preset=preset-3] .swal-button:active {
  background-color: #5e4fe2;
}
[data-pc-preset=preset-3] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(114, 101, 230, 0.29);
}
[data-pc-preset=preset-3] .swal-content__input:focus {
  border-color: rgba(114, 101, 230, 0.29);
}
[data-pc-preset=preset-3] .swal-content__textarea:focus {
  border-color: rgba(114, 101, 230, 0.29);
}
[data-pc-preset=preset-3] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(114, 101, 230, 0.4) !important;
}
[data-pc-preset=preset-3] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #c2bdf4 0, #c2bdf4 100%);
}
[data-pc-preset=preset-3] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #7265e6;
  --bs-table-border-color: #8074e9;
  --bs-table-striped-bg: #796de7;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #8074e9;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #7568e7;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-4] {
  --pc-sidebar-active-color: #068e44;
  --bs-blue: #068e44;
  --bs-primary: #068e44;
  --bs-primary-rgb: 6, 142, 68;
  --bs-primary-light: #e6f4ec;
  --bs-link-color: #068e44;
  --bs-link-color-rgb: 6, 142, 68;
  --bs-link-hover-color: #057236;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 6, 142, 68;
}
[data-pc-preset=preset-4] .bg-light-primary {
  background: #e6f4ec;
  color: #068e44;
}
[data-pc-preset=preset-4] .link-primary {
  color: #068e44 !important;
}
[data-pc-preset=preset-4] .link-primary:hover, [data-pc-preset=preset-4] .link-primary:focus {
  color: #057236 !important;
}
[data-pc-preset=preset-4] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #068e44;
  --bs-btn-border-color: #068e44;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #05793a;
  --bs-btn-hover-border-color: #057236;
  --bs-btn-focus-shadow-rgb: 43, 159, 96;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #057236;
  --bs-btn-active-border-color: #056b33;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #068e44;
  --bs-btn-disabled-border-color: #068e44;
}
[data-pc-preset=preset-4] .btn-link {
  --bs-btn-color: #068e44;
  --bs-btn-hover-color: #057236;
  --bs-btn-active-color: #057236;
}
[data-pc-preset=preset-4] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(6, 142, 68, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-4] .accordion {
  --bs-accordion-btn-focus-border-color: #068e44;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(6, 142, 68, 0.25);
  --bs-accordion-active-color: #068e44;
  --bs-accordion-active-bg: #e6f4ec;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23068e44'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .alert-primary {
  --bs-alert-color: #045529;
  --bs-alert-bg: #cde8da;
  --bs-alert-border-color: #b4ddc7;
  --bs-alert-link-color: #034421;
}
[data-pc-preset=preset-4] .list-group {
  --bs-list-group-active-bg: #068e44;
  --bs-list-group-active-border-color: #068e44;
}
[data-pc-preset=preset-4] .list-group-item-primary {
  color: #045529;
  background-color: #cde8da;
}
[data-pc-preset=preset-4] .nav {
  --bs-nav-link-hover-color: #057236;
}
[data-pc-preset=preset-4] .nav-pills {
  --bs-nav-pills-link-active-bg: #068e44;
}
[data-pc-preset=preset-4] .pagination {
  --bs-pagination-hover-color: #057236;
  --bs-pagination-focus-color: #057236;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(6, 142, 68, 0.25);
  --bs-pagination-active-bg: #068e44;
  --bs-pagination-active-border-color: #068e44;
}
[data-pc-preset=preset-4] .progress {
  --bs-progress-bar-bg: #068e44;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:checked {
  border-color: #068e44;
  background-color: #068e44;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f4ec;
  background-color: #e6f4ec;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23068e44' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23068e44'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(6, 142, 68, 0.25);
  border-color: #068e44;
}
[data-pc-preset=preset-4] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23068e44'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .btn-light-primary {
  background: #e6f4ec;
  color: #068e44;
  border-color: #e6f4ec;
}
[data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #068e44;
}
[data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #068e44;
  color: #fff;
  border-color: #068e44;
}
[data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #068e44;
  color: #fff;
  border-color: #068e44;
}
[data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #068e44;
  color: #fff;
  border-color: #068e44;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #068e44;
  color: #fff;
  border-color: #068e44;
}
[data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #068e44;
  border-color: transparent;
}
[data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #068e44;
}
[data-pc-preset=preset-4] .btn-link-primary:hover {
  background: #e6f4ec;
  color: #068e44;
  border-color: #e6f4ec;
}
[data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-preset=preset-4] .btn-link-primary:focus {
  background: #e6f4ec;
  color: #068e44;
  border-color: #e6f4ec;
}
[data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: #e6f4ec;
  color: #068e44;
  border-color: #e6f4ec;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: #e6f4ec;
  color: #068e44;
  border-color: #e6f4ec;
}
[data-pc-preset=preset-4] .slider-selection {
  background-image: linear-gradient(to bottom, #1ef580 0, #1ef580 100%);
}
[data-pc-preset=preset-4] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #0af073 0, #0af073 100%);
}
[data-pc-preset=preset-4] .swal-button:not([disabled]):hover {
  background-color: #057638;
}
[data-pc-preset=preset-4] .swal-button:active {
  background-color: #057638;
}
[data-pc-preset=preset-4] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(6, 142, 68, 0.29);
}
[data-pc-preset=preset-4] .swal-content__input:focus {
  border-color: rgba(6, 142, 68, 0.29);
}
[data-pc-preset=preset-4] .swal-content__textarea:focus {
  border-color: rgba(6, 142, 68, 0.29);
}
[data-pc-preset=preset-4] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(6, 142, 68, 0.4) !important;
}
[data-pc-preset=preset-4] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #0af073 0, #0af073 100%);
}
[data-pc-preset=preset-4] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #068e44;
  --bs-table-border-color: #1f9957;
  --bs-table-striped-bg: #12944d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1f9957;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #0b9048;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-5] {
  --pc-sidebar-active-color: #3c64d0;
  --bs-blue: #3c64d0;
  --bs-primary: #3c64d0;
  --bs-primary-rgb: 60, 100, 208;
  --bs-primary-light: #ecf0fa;
  --bs-link-color: #3c64d0;
  --bs-link-color-rgb: 60, 100, 208;
  --bs-link-hover-color: #3050a6;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 60, 100, 208;
}
[data-pc-preset=preset-5] .bg-light-primary {
  background: #ecf0fa;
  color: #3c64d0;
}
[data-pc-preset=preset-5] .link-primary {
  color: #3c64d0 !important;
}
[data-pc-preset=preset-5] .link-primary:hover, [data-pc-preset=preset-5] .link-primary:focus {
  color: #3050a6 !important;
}
[data-pc-preset=preset-5] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3c64d0;
  --bs-btn-border-color: #3c64d0;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3355b1;
  --bs-btn-hover-border-color: #3050a6;
  --bs-btn-focus-shadow-rgb: 89, 123, 215;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3050a6;
  --bs-btn-active-border-color: #2d4b9c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3c64d0;
  --bs-btn-disabled-border-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-link {
  --bs-btn-color: #3c64d0;
  --bs-btn-hover-color: #3050a6;
  --bs-btn-active-color: #3050a6;
}
[data-pc-preset=preset-5] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(60, 100, 208, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-5] .accordion {
  --bs-accordion-btn-focus-border-color: #3c64d0;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(60, 100, 208, 0.25);
  --bs-accordion-active-color: #3c64d0;
  --bs-accordion-active-bg: #ecf0fa;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233c64d0'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .alert-primary {
  --bs-alert-color: #243c7d;
  --bs-alert-bg: #d8e0f6;
  --bs-alert-border-color: #c5d1f1;
  --bs-alert-link-color: #1d3064;
}
[data-pc-preset=preset-5] .list-group {
  --bs-list-group-active-bg: #3c64d0;
  --bs-list-group-active-border-color: #3c64d0;
}
[data-pc-preset=preset-5] .list-group-item-primary {
  color: #243c7d;
  background-color: #d8e0f6;
}
[data-pc-preset=preset-5] .nav {
  --bs-nav-link-hover-color: #3050a6;
}
[data-pc-preset=preset-5] .nav-pills {
  --bs-nav-pills-link-active-bg: #3c64d0;
}
[data-pc-preset=preset-5] .pagination {
  --bs-pagination-hover-color: #3050a6;
  --bs-pagination-focus-color: #3050a6;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(60, 100, 208, 0.25);
  --bs-pagination-active-bg: #3c64d0;
  --bs-pagination-active-border-color: #3c64d0;
}
[data-pc-preset=preset-5] .progress {
  --bs-progress-bar-bg: #3c64d0;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:checked {
  border-color: #3c64d0;
  background-color: #3c64d0;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ecf0fa;
  background-color: #ecf0fa;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233c64d0' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233c64d0'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(60, 100, 208, 0.25);
  border-color: #3c64d0;
}
[data-pc-preset=preset-5] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233c64d0'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .btn-light-primary {
  background: #ecf0fa;
  color: #3c64d0;
  border-color: #ecf0fa;
}
[data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #3c64d0;
  color: #fff;
  border-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #3c64d0;
  color: #fff;
  border-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #3c64d0;
  color: #fff;
  border-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #3c64d0;
  color: #fff;
  border-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #3c64d0;
  border-color: transparent;
}
[data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #3c64d0;
}
[data-pc-preset=preset-5] .btn-link-primary:hover {
  background: #ecf0fa;
  color: #3c64d0;
  border-color: #ecf0fa;
}
[data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-preset=preset-5] .btn-link-primary:focus {
  background: #ecf0fa;
  color: #3c64d0;
  border-color: #ecf0fa;
}
[data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: #ecf0fa;
  color: #3c64d0;
  border-color: #ecf0fa;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: #ecf0fa;
  color: #3c64d0;
  border-color: #ecf0fa;
}
[data-pc-preset=preset-5] .slider-selection {
  background-image: linear-gradient(to bottom, #a3b6e9 0, #a3b6e9 100%);
}
[data-pc-preset=preset-5] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #8ea5e4 0, #8ea5e4 100%);
}
[data-pc-preset=preset-5] .swal-button:not([disabled]):hover {
  background-color: #2f57c3;
}
[data-pc-preset=preset-5] .swal-button:active {
  background-color: #2f57c3;
}
[data-pc-preset=preset-5] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(60, 100, 208, 0.29);
}
[data-pc-preset=preset-5] .swal-content__input:focus {
  border-color: rgba(60, 100, 208, 0.29);
}
[data-pc-preset=preset-5] .swal-content__textarea:focus {
  border-color: rgba(60, 100, 208, 0.29);
}
[data-pc-preset=preset-5] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(60, 100, 208, 0.4) !important;
}
[data-pc-preset=preset-5] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #8ea5e4 0, #8ea5e4 100%);
}
[data-pc-preset=preset-5] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3c64d0;
  --bs-table-border-color: #5074d5;
  --bs-table-striped-bg: #466cd2;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #5074d5;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #4067d1;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-6] {
  --pc-sidebar-active-color: #f27013;
  --bs-blue: #f27013;
  --bs-primary: #f27013;
  --bs-primary-rgb: 242, 112, 19;
  --bs-primary-light: #fef1e7;
  --bs-link-color: #f27013;
  --bs-link-color-rgb: 242, 112, 19;
  --bs-link-hover-color: #c25a0f;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 242, 112, 19;
}
[data-pc-preset=preset-6] .bg-light-primary {
  background: #fef1e7;
  color: #f27013;
}
[data-pc-preset=preset-6] .link-primary {
  color: #f27013 !important;
}
[data-pc-preset=preset-6] .link-primary:hover, [data-pc-preset=preset-6] .link-primary:focus {
  color: #c25a0f !important;
}
[data-pc-preset=preset-6] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f27013;
  --bs-btn-border-color: #f27013;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ce5f10;
  --bs-btn-hover-border-color: #c25a0f;
  --bs-btn-focus-shadow-rgb: 244, 133, 54;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c25a0f;
  --bs-btn-active-border-color: #b6540e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f27013;
  --bs-btn-disabled-border-color: #f27013;
}
[data-pc-preset=preset-6] .btn-link {
  --bs-btn-color: #f27013;
  --bs-btn-hover-color: #c25a0f;
  --bs-btn-active-color: #c25a0f;
}
[data-pc-preset=preset-6] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(242, 112, 19, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-6] .accordion {
  --bs-accordion-btn-focus-border-color: #f27013;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(242, 112, 19, 0.25);
  --bs-accordion-active-color: #f27013;
  --bs-accordion-active-bg: #fef1e7;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f27013'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .alert-primary {
  --bs-alert-color: #91430b;
  --bs-alert-bg: #fce2d0;
  --bs-alert-border-color: #fbd4b8;
  --bs-alert-link-color: #743609;
}
[data-pc-preset=preset-6] .list-group {
  --bs-list-group-active-bg: #f27013;
  --bs-list-group-active-border-color: #f27013;
}
[data-pc-preset=preset-6] .list-group-item-primary {
  color: #91430b;
  background-color: #fce2d0;
}
[data-pc-preset=preset-6] .nav {
  --bs-nav-link-hover-color: #c25a0f;
}
[data-pc-preset=preset-6] .nav-pills {
  --bs-nav-pills-link-active-bg: #f27013;
}
[data-pc-preset=preset-6] .pagination {
  --bs-pagination-hover-color: #c25a0f;
  --bs-pagination-focus-color: #c25a0f;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(242, 112, 19, 0.25);
  --bs-pagination-active-bg: #f27013;
  --bs-pagination-active-border-color: #f27013;
}
[data-pc-preset=preset-6] .progress {
  --bs-progress-bar-bg: #f27013;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:checked {
  border-color: #f27013;
  background-color: #f27013;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fef1e7;
  background-color: #fef1e7;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f27013' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f27013'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(242, 112, 19, 0.25);
  border-color: #f27013;
}
[data-pc-preset=preset-6] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f27013'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .btn-light-primary {
  background: #fef1e7;
  color: #f27013;
  border-color: #fef1e7;
}
[data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #f27013;
}
[data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #f27013;
  color: #fff;
  border-color: #f27013;
}
[data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #f27013;
  color: #fff;
  border-color: #f27013;
}
[data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #f27013;
  color: #fff;
  border-color: #f27013;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #f27013;
  color: #fff;
  border-color: #f27013;
}
[data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #f27013;
  border-color: transparent;
}
[data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #f27013;
}
[data-pc-preset=preset-6] .btn-link-primary:hover {
  background: #fef1e7;
  color: #f27013;
  border-color: #fef1e7;
}
[data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-preset=preset-6] .btn-link-primary:focus {
  background: #fef1e7;
  color: #f27013;
  border-color: #fef1e7;
}
[data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: #fef1e7;
  color: #f27013;
  border-color: #fef1e7;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: #fef1e7;
  color: #f27013;
  border-color: #fef1e7;
}
[data-pc-preset=preset-6] .slider-selection {
  background-image: linear-gradient(to bottom, #f9b98c 0, #f9b98c 100%);
}
[data-pc-preset=preset-6] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #f7ab74 0, #f7ab74 100%);
}
[data-pc-preset=preset-6] .swal-button:not([disabled]):hover {
  background-color: #df640c;
}
[data-pc-preset=preset-6] .swal-button:active {
  background-color: #df640c;
}
[data-pc-preset=preset-6] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(242, 112, 19, 0.29);
}
[data-pc-preset=preset-6] .swal-content__input:focus {
  border-color: rgba(242, 112, 19, 0.29);
}
[data-pc-preset=preset-6] .swal-content__textarea:focus {
  border-color: rgba(242, 112, 19, 0.29);
}
[data-pc-preset=preset-6] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(242, 112, 19, 0.4) !important;
}
[data-pc-preset=preset-6] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #f7ab74 0, #f7ab74 100%);
}
[data-pc-preset=preset-6] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #f27013;
  --bs-table-border-color: #f37e2b;
  --bs-table-striped-bg: #f3771f;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #f37e2b;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #f27318;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-7] {
  --pc-sidebar-active-color: #2aa1af;
  --bs-blue: #2aa1af;
  --bs-primary: #2aa1af;
  --bs-primary-rgb: 42, 161, 175;
  --bs-primary-light: #eaf6f7;
  --bs-link-color: #2aa1af;
  --bs-link-color-rgb: 42, 161, 175;
  --bs-link-hover-color: #22818c;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 42, 161, 175;
}
[data-pc-preset=preset-7] .bg-light-primary {
  background: #eaf6f7;
  color: #2aa1af;
}
[data-pc-preset=preset-7] .link-primary {
  color: #2aa1af !important;
}
[data-pc-preset=preset-7] .link-primary:hover, [data-pc-preset=preset-7] .link-primary:focus {
  color: #22818c !important;
}
[data-pc-preset=preset-7] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2aa1af;
  --bs-btn-border-color: #2aa1af;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #248995;
  --bs-btn-hover-border-color: #22818c;
  --bs-btn-focus-shadow-rgb: 74, 175, 187;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #22818c;
  --bs-btn-active-border-color: #207983;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2aa1af;
  --bs-btn-disabled-border-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-link {
  --bs-btn-color: #2aa1af;
  --bs-btn-hover-color: #22818c;
  --bs-btn-active-color: #22818c;
}
[data-pc-preset=preset-7] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(42, 161, 175, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-7] .accordion {
  --bs-accordion-btn-focus-border-color: #2aa1af;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(42, 161, 175, 0.25);
  --bs-accordion-active-color: #2aa1af;
  --bs-accordion-active-bg: #eaf6f7;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232aa1af'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .alert-primary {
  --bs-alert-color: #196169;
  --bs-alert-bg: #d4ecef;
  --bs-alert-border-color: #bfe3e7;
  --bs-alert-link-color: #144e54;
}
[data-pc-preset=preset-7] .list-group {
  --bs-list-group-active-bg: #2aa1af;
  --bs-list-group-active-border-color: #2aa1af;
}
[data-pc-preset=preset-7] .list-group-item-primary {
  color: #196169;
  background-color: #d4ecef;
}
[data-pc-preset=preset-7] .nav {
  --bs-nav-link-hover-color: #22818c;
}
[data-pc-preset=preset-7] .nav-pills {
  --bs-nav-pills-link-active-bg: #2aa1af;
}
[data-pc-preset=preset-7] .pagination {
  --bs-pagination-hover-color: #22818c;
  --bs-pagination-focus-color: #22818c;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(42, 161, 175, 0.25);
  --bs-pagination-active-bg: #2aa1af;
  --bs-pagination-active-border-color: #2aa1af;
}
[data-pc-preset=preset-7] .progress {
  --bs-progress-bar-bg: #2aa1af;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:checked {
  border-color: #2aa1af;
  background-color: #2aa1af;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: #eaf6f7;
  background-color: #eaf6f7;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232aa1af' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232aa1af'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(42, 161, 175, 0.25);
  border-color: #2aa1af;
}
[data-pc-preset=preset-7] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232aa1af'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .btn-light-primary {
  background: #eaf6f7;
  color: #2aa1af;
  border-color: #eaf6f7;
}
[data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #2aa1af;
  color: #fff;
  border-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #2aa1af;
  color: #fff;
  border-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #2aa1af;
  color: #fff;
  border-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #2aa1af;
  color: #fff;
  border-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #2aa1af;
  border-color: transparent;
}
[data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #2aa1af;
}
[data-pc-preset=preset-7] .btn-link-primary:hover {
  background: #eaf6f7;
  color: #2aa1af;
  border-color: #eaf6f7;
}
[data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-preset=preset-7] .btn-link-primary:focus {
  background: #eaf6f7;
  color: #2aa1af;
  border-color: #eaf6f7;
}
[data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: #eaf6f7;
  color: #2aa1af;
  border-color: #eaf6f7;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: #eaf6f7;
  color: #2aa1af;
  border-color: #eaf6f7;
}
[data-pc-preset=preset-7] .slider-selection {
  background-image: linear-gradient(to bottom, #7ad4df 0, #7ad4df 100%);
}
[data-pc-preset=preset-7] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #65ceda 0, #65ceda 100%);
}
[data-pc-preset=preset-7] .swal-button:not([disabled]):hover {
  background-color: #258e9a;
}
[data-pc-preset=preset-7] .swal-button:active {
  background-color: #258e9a;
}
[data-pc-preset=preset-7] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(42, 161, 175, 0.29);
}
[data-pc-preset=preset-7] .swal-content__input:focus {
  border-color: rgba(42, 161, 175, 0.29);
}
[data-pc-preset=preset-7] .swal-content__textarea:focus {
  border-color: rgba(42, 161, 175, 0.29);
}
[data-pc-preset=preset-7] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(42, 161, 175, 0.4) !important;
}
[data-pc-preset=preset-7] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #65ceda 0, #65ceda 100%);
}
[data-pc-preset=preset-7] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #2aa1af;
  --bs-table-border-color: #3faab7;
  --bs-table-striped-bg: #35a6b3;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3faab7;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #2ea3b1;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-8] {
  --pc-sidebar-active-color: #00a854;
  --bs-blue: #00a854;
  --bs-primary: #00a854;
  --bs-primary-rgb: 0, 168, 84;
  --bs-primary-light: #e6f6ee;
  --bs-link-color: #00a854;
  --bs-link-color-rgb: 0, 168, 84;
  --bs-link-hover-color: #008643;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 0, 168, 84;
}
[data-pc-preset=preset-8] .bg-light-primary {
  background: #e6f6ee;
  color: #00a854;
}
[data-pc-preset=preset-8] .link-primary {
  color: #00a854 !important;
}
[data-pc-preset=preset-8] .link-primary:hover, [data-pc-preset=preset-8] .link-primary:focus {
  color: #008643 !important;
}
[data-pc-preset=preset-8] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #00a854;
  --bs-btn-border-color: #00a854;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #008f47;
  --bs-btn-hover-border-color: #008643;
  --bs-btn-focus-shadow-rgb: 38, 181, 110;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #008643;
  --bs-btn-active-border-color: #007e3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #00a854;
  --bs-btn-disabled-border-color: #00a854;
}
[data-pc-preset=preset-8] .btn-link {
  --bs-btn-color: #00a854;
  --bs-btn-hover-color: #008643;
  --bs-btn-active-color: #008643;
}
[data-pc-preset=preset-8] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(0, 168, 84, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-8] .accordion {
  --bs-accordion-btn-focus-border-color: #00a854;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 168, 84, 0.25);
  --bs-accordion-active-color: #00a854;
  --bs-accordion-active-bg: #e6f6ee;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300a854'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .alert-primary {
  --bs-alert-color: #006532;
  --bs-alert-bg: #cceedd;
  --bs-alert-border-color: #b3e5cc;
  --bs-alert-link-color: #005128;
}
[data-pc-preset=preset-8] .list-group {
  --bs-list-group-active-bg: #00a854;
  --bs-list-group-active-border-color: #00a854;
}
[data-pc-preset=preset-8] .list-group-item-primary {
  color: #006532;
  background-color: #cceedd;
}
[data-pc-preset=preset-8] .nav {
  --bs-nav-link-hover-color: #008643;
}
[data-pc-preset=preset-8] .nav-pills {
  --bs-nav-pills-link-active-bg: #00a854;
}
[data-pc-preset=preset-8] .pagination {
  --bs-pagination-hover-color: #008643;
  --bs-pagination-focus-color: #008643;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(0, 168, 84, 0.25);
  --bs-pagination-active-bg: #00a854;
  --bs-pagination-active-border-color: #00a854;
}
[data-pc-preset=preset-8] .progress {
  --bs-progress-bar-bg: #00a854;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:checked {
  border-color: #00a854;
  background-color: #00a854;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f6ee;
  background-color: #e6f6ee;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300a854' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2300a854'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 168, 84, 0.25);
  border-color: #00a854;
}
[data-pc-preset=preset-8] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300a854'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .btn-light-primary {
  background: #e6f6ee;
  color: #00a854;
  border-color: #e6f6ee;
}
[data-pc-preset=preset-8] .btn-light-primary .material-icons-two-tone {
  background-color: #00a854;
}
[data-pc-preset=preset-8] .btn-light-primary:hover {
  background: #00a854;
  color: #fff;
  border-color: #00a854;
}
[data-pc-preset=preset-8] .btn-light-primary.focus, [data-pc-preset=preset-8] .btn-light-primary:focus {
  background: #00a854;
  color: #fff;
  border-color: #00a854;
}
[data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-light-primary.dropdown-toggle {
  background: #00a854;
  color: #fff;
  border-color: #00a854;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-light-primary {
  background: #00a854;
  color: #fff;
  border-color: #00a854;
}
[data-pc-preset=preset-8] .btn-link-primary {
  background: transparent;
  color: #00a854;
  border-color: transparent;
}
[data-pc-preset=preset-8] .btn-link-primary .material-icons-two-tone {
  background-color: #00a854;
}
[data-pc-preset=preset-8] .btn-link-primary:hover {
  background: #e6f6ee;
  color: #00a854;
  border-color: #e6f6ee;
}
[data-pc-preset=preset-8] .btn-link-primary.focus, [data-pc-preset=preset-8] .btn-link-primary:focus {
  background: #e6f6ee;
  color: #00a854;
  border-color: #e6f6ee;
}
[data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-link-primary.dropdown-toggle {
  background: #e6f6ee;
  color: #00a854;
  border-color: #e6f6ee;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-link-primary {
  background: #e6f6ee;
  color: #00a854;
  border-color: #e6f6ee;
}
[data-pc-preset=preset-8] .slider-selection {
  background-image: linear-gradient(to bottom, #29ff94 0, #29ff94 100%);
}
[data-pc-preset=preset-8] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #0fff87 0, #0fff87 100%);
}
[data-pc-preset=preset-8] .swal-button:not([disabled]):hover {
  background-color: #008f47;
}
[data-pc-preset=preset-8] .swal-button:active {
  background-color: #008f47;
}
[data-pc-preset=preset-8] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 168, 84, 0.29);
}
[data-pc-preset=preset-8] .swal-content__input:focus {
  border-color: rgba(0, 168, 84, 0.29);
}
[data-pc-preset=preset-8] .swal-content__textarea:focus {
  border-color: rgba(0, 168, 84, 0.29);
}
[data-pc-preset=preset-8] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 168, 84, 0.4) !important;
}
[data-pc-preset=preset-8] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #0fff87 0, #0fff87 100%);
}
[data-pc-preset=preset-8] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #00a854;
  --bs-table-border-color: #1ab165;
  --bs-table-striped-bg: #0dac5d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1ab165;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #05aa57;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-9] {
  --pc-sidebar-active-color: #009688;
  --bs-blue: #009688;
  --bs-primary: #009688;
  --bs-primary-rgb: 0, 150, 136;
  --bs-primary-light: #e6f5f3;
  --bs-link-color: #009688;
  --bs-link-color-rgb: 0, 150, 136;
  --bs-link-hover-color: #00786d;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 0, 150, 136;
}
[data-pc-preset=preset-9] .bg-light-primary {
  background: #e6f5f3;
  color: #009688;
}
[data-pc-preset=preset-9] .link-primary {
  color: #009688 !important;
}
[data-pc-preset=preset-9] .link-primary:hover, [data-pc-preset=preset-9] .link-primary:focus {
  color: #00786d !important;
}
[data-pc-preset=preset-9] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #009688;
  --bs-btn-border-color: #009688;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #008074;
  --bs-btn-hover-border-color: #00786d;
  --bs-btn-focus-shadow-rgb: 38, 166, 154;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #00786d;
  --bs-btn-active-border-color: #007166;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #009688;
  --bs-btn-disabled-border-color: #009688;
}
[data-pc-preset=preset-9] .btn-link {
  --bs-btn-color: #009688;
  --bs-btn-hover-color: #00786d;
  --bs-btn-active-color: #00786d;
}
[data-pc-preset=preset-9] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(0, 150, 136, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-9] .accordion {
  --bs-accordion-btn-focus-border-color: #009688;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.25);
  --bs-accordion-active-color: #009688;
  --bs-accordion-active-bg: #e6f5f3;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23009688'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .alert-primary {
  --bs-alert-color: #005a52;
  --bs-alert-bg: #cceae7;
  --bs-alert-border-color: #b3e0db;
  --bs-alert-link-color: #004842;
}
[data-pc-preset=preset-9] .list-group {
  --bs-list-group-active-bg: #009688;
  --bs-list-group-active-border-color: #009688;
}
[data-pc-preset=preset-9] .list-group-item-primary {
  color: #005a52;
  background-color: #cceae7;
}
[data-pc-preset=preset-9] .nav {
  --bs-nav-link-hover-color: #00786d;
}
[data-pc-preset=preset-9] .nav-pills {
  --bs-nav-pills-link-active-bg: #009688;
}
[data-pc-preset=preset-9] .pagination {
  --bs-pagination-hover-color: #00786d;
  --bs-pagination-focus-color: #00786d;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.25);
  --bs-pagination-active-bg: #009688;
  --bs-pagination-active-border-color: #009688;
}
[data-pc-preset=preset-9] .progress {
  --bs-progress-bar-bg: #009688;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:checked {
  border-color: #009688;
  background-color: #009688;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f5f3;
  background-color: #e6f5f3;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23009688' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23009688'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.25);
  border-color: #009688;
}
[data-pc-preset=preset-9] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23009688'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .btn-light-primary {
  background: #e6f5f3;
  color: #009688;
  border-color: #e6f5f3;
}
[data-pc-preset=preset-9] .btn-light-primary .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-preset=preset-9] .btn-light-primary:hover {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-9] .btn-light-primary.focus, [data-pc-preset=preset-9] .btn-light-primary:focus {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-light-primary.dropdown-toggle {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-light-primary {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-9] .btn-link-primary {
  background: transparent;
  color: #009688;
  border-color: transparent;
}
[data-pc-preset=preset-9] .btn-link-primary .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-preset=preset-9] .btn-link-primary:hover {
  background: #e6f5f3;
  color: #009688;
  border-color: #e6f5f3;
}
[data-pc-preset=preset-9] .btn-link-primary.focus, [data-pc-preset=preset-9] .btn-link-primary:focus {
  background: #e6f5f3;
  color: #009688;
  border-color: #e6f5f3;
}
[data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-link-primary.dropdown-toggle {
  background: #e6f5f3;
  color: #009688;
  border-color: #e6f5f3;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-link-primary {
  background: #e6f5f3;
  color: #009688;
  border-color: #e6f5f3;
}
[data-pc-preset=preset-9] .slider-selection {
  background-image: linear-gradient(to bottom, #17ffe9 0, #17ffe9 100%);
}
[data-pc-preset=preset-9] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #00fce4 0, #00fce4 100%);
}
[data-pc-preset=preset-9] .swal-button:not([disabled]):hover {
  background-color: #007d71;
}
[data-pc-preset=preset-9] .swal-button:active {
  background-color: #007d71;
}
[data-pc-preset=preset-9] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 150, 136, 0.29);
}
[data-pc-preset=preset-9] .swal-content__input:focus {
  border-color: rgba(0, 150, 136, 0.29);
}
[data-pc-preset=preset-9] .swal-content__textarea:focus {
  border-color: rgba(0, 150, 136, 0.29);
}
[data-pc-preset=preset-9] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 150, 136, 0.4) !important;
}
[data-pc-preset=preset-9] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #00fce4 0, #00fce4 100%);
}
[data-pc-preset=preset-9] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #009688;
  --bs-table-border-color: #1aa194;
  --bs-table-striped-bg: #0d9b8e;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1aa194;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #05988a;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}